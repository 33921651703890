import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "A Theory About the Next iPads and Side by Side Apps",
  "tags": "Apple iPad",
  "date": "2014-10-16T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "http://9to5mac.com/2014/06/11/heres-the-ipad-split-screen-app-mode-apple-is-working-on-in-ios-8-video/"
      }}>{`Countless rumors`}</a>{` have been pointing to the fact that Apple might introduce a way to run apps side by side on the iPad later today. For example, you could have Safari running on two thirds of the screen and a Twitter client running on the other third`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` of the screen. I, for one, think it would be a compelling feature that would enable you to do things you can’t do on an iPhone and maybe breathe new life into the iPad lineup.`}</p>
    <p>{`I’m a happy iPad Mini user. To me, the iPad Mini has become the “default-sized” iPad. The iPad Air in comparison just looks like a blown up version of the same UI, showing `}<em parentName="p">{`bigger`}</em>{` content, not more content. Yes, it will still shine when looking at photos or videos, but the fact that it’s way less portable than the Mini make it much less appealing to me.`}</p>
    <p>{`Now, imagine that the iPad Air as you know it today is running in the same “scaled” mode as the one you can get on the iPhone 6. With the new iPad Air, Apple could allow you to display things in “standard” mode (imagine iPad Mini-sized UI elements running on the iPad Air, effectively showing you `}<em parentName="p">{`more`}</em>{` content on a device with the same physical dimensions.) You’d now have a meaningful difference between the iPad Air and the Mini, and the extra screen size would more than make up for the device being less portable.`}</p>
    <p>{`In “normal” scaling mode, the new iPad Air would be able run side by side apps in portrait and landscape (a benefit of having more real estate to play with) and the iPad Mini would still be able to run side by side apps, but only in landscape where each app would have enough horizontal real estate.`}</p>
    <p>{`I think a this shift would be in line with Apple’s message to developers this year: make universal apps that can adapt at any size and take advantage of bigger displays. They’re now finally in a place where apps can be flexible and I don’t see why the wouldn’t take advantage of that.`}</p>
    <p>{`Writing a piece on the day of an Apple announcement is a very scary thing. Tim Cook might very well prove me wrong on this one, hence why I’m dubbing this as a theory of something they could do to position the iPad as a content creation device and take full advantage of things that would only be possible on an iPad.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`Microsoft is already doing something similar with Windows 8.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      